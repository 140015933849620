import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PlatformEnvDataProvider,
	SiteFeatureConfigSymbol,
	IMultilingual,
	MultilingualSiteConfig,
	MultilingualCurrentLanguageSymbol,
	ICurrentLanguageAPI,
} from '@wix/thunderbolt-symbols'

import { name } from './symbols'

const multilingualFactory = (
	siteFeatureConfig: MultilingualSiteConfig,
	CurrentLanguageAPI: ICurrentLanguageAPI
): IMultilingual & PlatformEnvDataProvider => {
	const { isEnabled, isOriginalLanguage, siteLanguages } = siteFeatureConfig
	const { set: setCurrentLanguage, get: getCurrentLanguage } = CurrentLanguageAPI

	return {
		platformEnvData: {
			multilingual: {
				isEnabled,
				isOriginalLanguage,
				siteLanguages,
				get currentLanguage() {
					return getCurrentLanguage()
				},
			},
		},
		isEnabled,
		isOriginalLanguage,
		siteLanguages,
		get currentLanguage() {
			return getCurrentLanguage()
		},
		setCurrentLanguage,
	}
}

export const Multilingual = withDependencies(
	[named(SiteFeatureConfigSymbol, name), MultilingualCurrentLanguageSymbol],
	multilingualFactory
)

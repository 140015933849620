import { ResolutionMethod } from '@wix/thunderbolt-ssr-api'

function setSubDomainOrDefault(url: URL, param = 'www') {
	url.host = url.host.replace(/^(\w{2,3})\.(.*)/, `${param}.$2`)
}

function setOrRemoveLangQueryParam(url: URL, param?: string) {
	if (param) {
		url.searchParams.set('lang', param)
	} else {
		url.searchParams.delete('lang')
	}
}

function stripUrlFromLangParam(url: URL) {
	const urlTo = new URL(url.href)
	setSubDomainOrDefault(urlTo)
	setOrRemoveLangQueryParam(urlTo)
	return urlTo
}

export function resolveLanguageUrl(resolutionMethod: ResolutionMethod, url: URL, languageCode?: string): string {
	const urlTo = stripUrlFromLangParam(url)
	const modifyer = resolutionMethod === 'Subdomain' ? setSubDomainOrDefault : setOrRemoveLangQueryParam
	modifyer(urlTo, languageCode)
	return urlTo.toString()
}

import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { MultilingualSiteConfig, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { CookiesManagerSymbol, ICookiesManager } from 'feature-cookies-manager'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { resolveLanguageUrl } from './urlResolver'
import { name } from './symbols'

export const CurrentLanguage = withDependencies(
	[named(SiteFeatureConfigSymbol, name), CookiesManagerSymbol, UrlHistoryManagerSymbol],
	(
		siteFeatureConfig: MultilingualSiteConfig,
		cookieManager: ICookiesManager,
		urlHistoryManager: IUrlHistoryManager
	) => {
		const { siteLanguages, originalLanguage, domain, currentLanguage } = siteFeatureConfig

		const getCurrentLanguage = () => currentLanguage
		const setCurrentLanguage = (languageCode: string) => {
			const chosenLanguage = siteLanguages.find((lang) => lang.languageCode === languageCode)!
			if (!chosenLanguage) {
				throw new Error(`language code "${languageCode}" is invalid`)
			}

			cookieManager.writeCookie('wixLanguage', languageCode, 'functional', {
				daysExpire: 6 * 30,
				domain,
			})

			const parsedUrl = urlHistoryManager.getParsedUrl()
			const isNextLanguageOriginal = originalLanguage.languageCode === languageCode
			const languageUrl = resolveLanguageUrl(
				chosenLanguage.resolutionMethod,
				parsedUrl,
				isNextLanguageOriginal ? undefined : languageCode
			)
			location.assign(languageUrl)
		}

		return {
			set: setCurrentLanguage,
			get: getCurrentLanguage,
		}
	}
)
